import { Component, OnInit, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CorporateService } from 'src/app/services/corporate.service';
import { HistoryService } from 'src/app/services/history.service';
import { Helper } from '../../../shared/helper';
import { SocketService } from "../../../services/socket.service";
@Component({
  selector: 'app-sidebar2',
  templateUrl: './sidebar2.component.html',
  styleUrls: ['./sidebar2.component.scss']
})
export class Sidebar2Component implements OnInit, OnDestroy {

  @Output() trip_data: EventEmitter<any> = new EventEmitter();
  @Output() manual_data: EventEmitter<any> = new EventEmitter();

  isOpen = true;
  is_click: boolean = false;
  request_list: any;
  accepted_request_list: any;
  arrived_request_list: any;
  started_request_list: any;
  selected_trip_id: string;
  selected_trip_unique_id: number;
  SearchText: any;
  user_detail: any;
  trip_id: string;

  SchedulemodalClose: BsModalRef;
  canceltripmodal: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm modal-dialog-centered'
  };

  constructor(private _socket: SocketService, private modalService: BsModalService, public helper: Helper, private _corporateService: CorporateService, private _historyService: HistoryService) { }

  ngOnInit(): void {
    if (this.is_click == false) {
      this.helper._route.navigate(['/app/map'])
    }

    if (this.helper.user_details) {
      this.socket(this.helper.user_details._id)
    }
    this.corporate_new_request(this.helper.user_details._id)
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  menuClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  openModal(): void {
    this.is_click = true;
    if (this.is_click == true) {
      this.helper._route.navigate(['/app/create-trip'])
    } else {
      this.helper._route.navigate(['/app/map'])
    }
    this.is_click = false;
  }
  socket(id: any) {
    let listner = id
    this._socket.listener(listner).subscribe((res: any) => {
      this.corporate_new_request(res.service_id)
    })
  }
  corporate_new_request(id) {
    let json: any = { dispatcher_id: id }
    this._corporateService.corporate_new_request(json).then((response) => {
      this.request_list = response.request_list;
      this.accepted_request_list = response.accepted_request_list;
      this.arrived_request_list = response.arrived_request_list;
      this.started_request_list = response.started_request_list;
    })
  }

  selected_user(data) {
    this.selected_trip_id = data._id;
    this.user_detail = data.user_detail;
    this.trip_data.emit(data);
  }

  assign(modal: TemplateRef<any>, i): void {
    this.SchedulemodalClose = this.modalService.show(modal, this.config);
    this.trip_id = this.request_list[i]._id;
    this.selected_trip_unique_id = this.request_list[i].unique_id;
  }

  send_request() {
    let json: any = { trip_id: this.trip_id, provider_type: 1, provider_full_name: '', provider_phone: '' }
    this._corporateService.send_request_from_corporate(json).then((response) => { })
  }

  manual_request() {
    this.manual_data.emit(2);
  }

  cancel_ride_modal(modal: TemplateRef<any>, trip_data): void {
    this.canceltripmodal = this.modalService.show(modal, this.config);
    this.selected_trip_id = trip_data._id;
    this.selected_trip_unique_id = trip_data.unique_id;
  }

  cancel_ride() {
    setTimeout(() => {
      let json: any = { user_id: this.user_detail._id, token: this.user_detail.token, trip_id: this.selected_trip_id }
      this._historyService.cancle_Trip(json).then((response) => {
        if(response.success){
          this.canceltripmodal.hide();
          this.corporate_new_request(this.helper.user_details._id);
          this.trip_data.emit(null);
        }
      })
    }, 1000);
  }
  ngOnDestroy(): void {
    this._corporateService = null;
  }

}
