import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { Helper } from 'src/app/shared/helper';
import { SocketService } from 'src/app/services/socket.service';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit {
  darkTheme = localStorage.getItem('vien-themecolor')
  logoClr:boolean=false;
  IMAGE_URL = environment.IMAGE_URL;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  constructor(
    private sidebarService: SidebarService,
    private _authService: AuthService,
    private langService: LangService,
    public helper: Helper,
    private _socket: SocketService,
    public router:Router,
    private _profileService:ProfileService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
    window.location.reload();
  }

  async ngOnInit(): Promise<void> {
    if(this.darkTheme.slice(0,4) == 'dark' ){
      this.logoClr=true;
    }    
    if (this.helper.user_details == null) {
      this.helper._route.navigate(['/corporate/login'])
    }
    if(this.helper.user_details){
      this.socket(this.helper.user_details._id)

      let json: any = { id: this.helper.user_details._id };
      json.is_show_success_toast = false;
      this._profileService.fetch_profile(json).then((corporate_data) => {
        this.helper.created_at.next(corporate_data.corporate_detail.created_at);
      })
    }    
  }

  socket(id: any) {
    let listner = id    
    this._socket.listener(listner).subscribe((res: any) => {            
      if (res.is_admin_decline) {
        this._authService.logout({type:9,is_admin_decline:true}).then(()=>{})
    }
    })
  }

  onSignOut(): void {
    this._authService.logout({type:9}).then(()=>{})
  }

	@Output() sidemenu: EventEmitter<any> = new EventEmitter();
  toggle(): void {
    this.sidemenu.emit()
  }

}
