import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { apiColletions } from '../constants/api_collection';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private _api: ApiService) { }

  get_history(parameters): Promise<any> {
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.completed_requests, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve(false)
          }
        })
      } catch {
        resolve(false)
      }
    })
  }
 
  get_future_history(parameters): Promise<any>{
    return new Promise((resolve, reject) => {

      try {
        this._api.post({ url: apiColletions.getfuturetrip, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)

          } else {
            resolve([])
          }
        })
      } catch {
        resolve([])
      }
    })
  }
  
  cancle_Trip(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.canceltrip, parameters }).then((response) => {

          if (response.success) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }
}
