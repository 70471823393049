export var apiColletions = {

    //Common
    "get_country_city_list": "/get_country_city_list",
    "get_setting_details": "/get_user_setting_detail",
    "get_language_list":"/get_language_list",

    //profile
    "corporate_web_profile":"/profile",
    "corporate_web_edit_profile":"/edit",
    "corporate_web_delete":"/delete",

    // Payment
    "get_card_list": "/payments/cards",
    "select_card": "/payments/card_selection",
    "add_card": "/payments/addcard",
    "get_stripe_add_card_intent": "/payments/get_stripe_add_card_intent",
    "get_stripe_payment_intent_wallet": "/payments/get_stripe_payment_intent",
    "send_paystack_required_detail": "/payments/send_paystack_required_detail",
    "add_wallet_amount": "/add_wallet_amount",
    "delete_card": "/payments/delete_card",
    "pay_stripe_intent_payment":"/pay_stripe_intent_payment",

    // Wallet History
    "corporate_web_wallet_history": "/wallet_history",

    //user
    "corporate_web_users":"/users",
    "corporate_web_send_request":"/send_request",
    "corporate_web_remove_user":"/remove_user",
    "corporate_web_add_limit":"/add_limit",

    // Auth
    "login": "/login",
    "register": "/register",
    "logout": "/sign_out",
    "forgot_pass": "/forgot_password",
    "corporate_web_update_psw":"/update_password",
    
    //completed requests
    "completed_requests":"/history",

    //future requests
    "getfuturetrip":"/future_request",

    //trip cancle
    "canceltrip":"/canceltrip",

    // excel sheet
    "corporate_genetare_request_excel":"/generate_request_excel",

    //create trip
    "createtrip":"/createtrip",

    // vehicle
    "typelist_selectedcountrycity": "/typelist_selectedcountrycity",
    "getfareestimate": "/getfareestimate",
    "get_nearby_provider": "/get_nearby_provider",
    "get_server_time" : "/get_server_time",
    "typelist_for_corporate":"/typelist_for_dispatcher",

    //provider
    "get_all_provider":"/get_all_provider",
    "getnearbyprovider":"/getnearbyprovider",

    //request
    "corporate_new_request":"/dispatcher_new_request",
    "send_request":"/send_request",

    //corporate trip user
    "corporate_web_trip_user_list":"/trip_user_list",

    //gettrippath
    "getgooglemappath":"/getgooglemappath",
}
