import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/shared/helper';
import { LangService ,Language} from 'src/app/shared/lang.service';

@Component({
  selector: 'app-topnav2',
  templateUrl: './topnav2.component.html',
  styleUrls: ['./topnav2.component.scss']
})
export class Topnav2Component implements OnInit {
  // IMAGE_URL = environment.IMAGE_URL;
  // DEFAULT_USER_PROFILE = DEFAULT_IMAGE.USER_PROFILE;
  languages: Language[];
  isSingleLang;

  constructor(public helper: Helper,private langService: LangService,private _authService: AuthService,) {
    this.languages = this.langService.supportedLanguages;
    this.isSingleLang = this.langService.isSingleLang;
  }

  ngOnInit(): void {
    if (this.helper.user_details == null) {
      this.helper._route.navigate(['/corporate/login'])
    }
  }
  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    window.location.reload();
  }
  onSignOut(): void {
    this._authService.logout({type:9}).then()
  }
}
