import { Injectable } from '@angular/core';
import { apiColletions } from '../constants/api_collection';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CorporateService {

  constructor(private _api: ApiService) { }

  corporate_new_request(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.corporate_new_request, parameters }).then((response) => {

          if (response.success) {
            resolve(response.data);
          } else {
            resolve(response);
          }
        })
      } catch (err) {
        resolve([]);
      }
    })
  }

  get_all_provider(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.get_all_provider, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  typelist_for_corporate(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.typelist_for_corporate, parameters }).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  send_request_from_corporate(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.send_request, parameters }).then(response => {
          if (response) {
            resolve(response)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  polyline(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.getgooglemappath, parameters }).then(response => {
          if (response) {
            resolve(response)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
}
