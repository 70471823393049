<div class="row">
  <div class="col-12">
    <!-- <h1>{{'heading-title.profile' | translate }}</h1> -->
    <app-heading></app-heading>
    <div class="top-right-button-container text-zero mb-3">
      <div dropdown class="d-inline-block dropleft">
        <button *ngIf="true" type="button" dropdownToggle=""
          class="btn btn-outline-primary userName d-flex align-item-center" aria-haspopup="true">
          <span class="userName truncate">{{this.langService.languageShorthand | uppercase}} </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" *ngFor="let item of languages"
            (click)="onLanguageChange(item)">{{item.label}}</a>
        </div>
      </div>
      <div class="user d-inline-block ml-2" dropdown>
        <button *ngIf="helper.user_details" type="button" dropdownToggle=""
          class="btn btn-outline-primary userName d-flex align-item-center" aria-haspopup="true">
          <!-- <img class="lazyload userimg" (error)="$event.target.src = DEFAULT_USER_PROFILE"
            [src]="helper.user_details.picture ? (IMAGE_URL + helper.user_details.picture) : DEFAULT_USER_PROFILE"
            [alt]="helper.user_details.first_name"> -->
          <span class="userName truncate d-none d-xs-block">{{helper.user_details.name}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right mt-3 ng-star-inserted show" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" routerLink="/app/">{{'label-title.account' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/user">{{'label-title.user' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/create-trip">{{'label-title.book-trip' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/history">{{'label-title.completed-requests' | translate}}</a>
          <a class="dropdown-item c-pointer" routerLink="/app/future-request">{{'label-title.future-request' | translate}}</a>
          <li class="divider dropdown-divider"></li>
          <a class="dropdown-item c-pointer" (click)="onSignOut()">{{'label-title.sign-out' | translate}}</a>
        </div>
      </div>
    </div>
    <app-breadcrumb></app-breadcrumb>
    <div class="separator mb-5"></div>
  </div>
</div>
