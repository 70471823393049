import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestfilter'
})
export class RequestFilterPipe implements PipeTransform {
  transform(request: any, SearchText: string): any {
    if(!SearchText) {
      return request;
    }
    return request.filter((data) => {
      return data.user_detail.first_name.toLowerCase().match(SearchText.toLowerCase()) || data.user_detail.phone.toLowerCase().match(SearchText.toLowerCase()) || data.unique_id.toString().match(SearchText.toLowerCase());
    });
  }

}