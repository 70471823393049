import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";
import { NotifiyService } from "./notifier.service";


@Injectable({ providedIn: 'root' })
export class AuthService {

    public logginUser: UserModel;
    public user_details = JSON.parse(localStorage.getItem('userData'));
    private loginSubject = new BehaviorSubject<UserModel>(this.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();


    get isAuthenticated(): boolean {
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }
    constructor(private _api: ApiService, private helper: Helper,private _notificationservice:NotifiyService) { }

    // Login

    login(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.login, parameters }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.corporate_detail;

                        var localStorageData = {
                            _id: this.logginUser._id,
                            token: this.logginUser.token,
                            country: this.logginUser.country_name,
                            name: this.logginUser.name,
                            email: this.logginUser.email,
                        }
                        this.helper.user_details = localStorageData;
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }

    logout(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.logout, parameters: parameters}).then(response => {
                    if (response.success) {
                        if(parameters.is_admin_decline){
                            this._notificationservice.showNotification('error', this.helper.trans.instant('error-code.4010'));
                        }
                        this.logginUser = null;
                        localStorage.removeItem('userData')
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigate(['/corporate/login'])
                        resolve(true)
                    } else {
                        resolve(null)
                    }
                })
            } catch (err) {
                resolve(null)
            }
        })
    }

    forgot_password_Email(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.forgot_pass, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    // Register

    register(parameters): Promise<boolean> {

        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.register, parameters }).then((response) => {

                    if (response.success) {

                        // this.logginUser = response.data.corporate_detail;
                        // this.loginSubject.next(this.logginUser);
                        // var localStorageData = {
                        //     _id: this.logginUser._id,
                        //     token: this.logginUser.token,
                        //     name: this.logginUser.name,
                        //     country: this.logginUser.country_name
                        // }
                        // localStorage.setItem('userData', JSON.stringify(localStorageData))
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
    
    new_password(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.corporate_web_update_psw, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
}
