import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { ApiService } from "./api.service";
// import { WalletHistory } from "../models/wallet-history.model";
import { Helper } from "../shared/helper";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public logginUser: UserModel;
  private loginSubject = new BehaviorSubject<UserModel>(this._helper.user_details);
  loginObservable: Observable<UserModel> = this.loginSubject.asObservable();

  constructor(private _api: ApiService, private _helper: Helper) { }

  fetch_profile(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.corporate_web_profile, parameters }).then((response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
      } catch (err) {
        resolve(null);
      }
    })
  }

  corporate_web_delete(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.corporate_web_delete, parameters }).then((response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            resolve(null);
          }
        })
      } catch (err) {
        resolve(null);
      }
    })
  }

  update_profile(profileForm): Promise<Boolean> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.corporate_web_edit_profile, parameters: profileForm }).then((response) => {

          if (response.success) {
            this.logginUser = response.data.corporate_detail;
            var localStorageData = {
              name: this.logginUser.name,
              country:this.logginUser.country_name,
              email:this.logginUser.email,
              token:this.logginUser.token,
              _id:this.logginUser._id
            }
            localStorage.setItem('userData', JSON.stringify(localStorageData))

            this.loginSubject.next(this.logginUser);
            resolve(true);
          } else {
            resolve(false);
          }
        })
      } catch (err) {
        resolve(false);
      }
    })
  }
}
